import React from 'react';

import Column from '../components/column';
import Container from '../components/container';
import Heading from '../components/heading';
import Layout from '../components/layout';
import Row from '../components/row';
import { Section } from '../components/section';

const NotFoundPage = () => (
  <Layout title="404">
    <Section>
      <Container>
        <Row>
          <Column offset="2">
            <Heading as="h1">Page not found</Heading>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default NotFoundPage;
